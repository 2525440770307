import "./App.css";

import { useEffect, useState, useCallback, useMemo } from "react";

import { createDirectus, rest, readItems, staticToken } from "@directus/sdk";

import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";

const DIRECTUS_URL = "https://setup.click-solutions.com";
const DIRECTUS_TOKEN = "iZE_33xuB9GuqFlhaPGb5rFR6fkb_NCf";

function App() {
  const [exhibition, setExhibition] = useState("");
  const [exhibit, setExhibit] = useState("");
  const [device, setDevice] = useState("");

  const [exhibitSelectDisabled, setExhibitSelectDisabled] = useState(true);
  const [deviceSelectDisabled, setDeviceSelectDisabled] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const [allExhibitions, setAllExhibitions] = useState([]);
  const [allExhibits, setAllExhibits] = useState([]);
  const [allDevices, setAllDevices] = useState([]);

  const [exhibitsInExhibition, setExhibitsInExhibition] = useState([]);
  const [devicesForExhibit, setDevicesForExhibit] = useState([]);

  const directus = useMemo(() => {
    return createDirectus(DIRECTUS_URL)
      .with(staticToken(DIRECTUS_TOKEN))
      .with(rest());
  }, []);

  const getData = useCallback(async () => {
    let exhibitions = await directus.request(
      readItems("exhibitions", {
        filter: {
          status: {
            _eq: "published",
          },
        },
        fields: ["id", "name", { exhibits: ["*"] }],
      })
    );

    let exhibits = await directus.request(
      readItems("exhibit", {
        fields: ["*", { devices: ["devices_id"] }],
      })
    );

    let devices = await directus.request(
      readItems("devices", {
        filter: {
          status: {
            _eq: "published",
          },
        },
        fields: ["id", "name", "hostname", "scripts"],
        limit: -1,
      })
    );

    setAllExhibitions(exhibitions);
    setAllExhibits(exhibits);
    setAllDevices(devices);
  }, [directus]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    //only enable select when items are available
    if (exhibitsInExhibition.length !== 0) {
      setExhibitSelectDisabled(false);
    }
    if (devicesForExhibit.length !== 0) {
      setDeviceSelectDisabled(false);
    }
  }, [device, devicesForExhibit, exhibitsInExhibition]);

  useEffect(() => {
    if (device === "") {
      return;
    }

    const target = allDevices.find((item) => {
      return item.id === device;
    });
    console.log(target);

    if (target?.scripts?.length <= 0) {
      alert("No Script available for this device.\nPlease Select another one.");
      return;
    }

    if (target?.scripts?.length > 0) {
      setButtonDisabled(false);
    }
  }, [allDevices, device]);

  const handleExhibitionChange = (event) => {
    setExhibition(event.target.value);

    //disable selects and buttons, only enable when items are available
    setExhibitSelectDisabled(true);
    setDeviceSelectDisabled(true);
    setButtonDisabled(true);

    // reset values
    setExhibit("");
    setDevice("");

    // reset exhibits
    setDevicesForExhibit([]);

    // filter exhibits
    const target = allExhibitions.find((exhibition) => {
      return exhibition.id === event.target.value;
    });

    let exhibitIds = target.exhibits.map((exhibit) => {
      return exhibit.exhibit_id;
    });

    const exhibits = allExhibits.filter((exhibit) => {
      return exhibitIds.includes(exhibit.id);
    });

    setExhibitsInExhibition(exhibits);
  };

  const handleExhibitChange = (event) => {
    setExhibit(event.target.value);

    //disable selects and buttons, only enable when items are available
    setDeviceSelectDisabled(true);
    setButtonDisabled(true);

    // reset values
    setDevicesForExhibit([]);
    setDevice("");

    // filter devices
    const target = allExhibits.find((exhibit) => {
      return exhibit.id === event.target.value;
    });

    const deviceIds = target?.devices?.map((device) => {
      return device.devices_id;
    });

    const devices = allDevices.filter((device) => {
      return deviceIds.includes(device.id);
    });

    setDevicesForExhibit(devices);
  };

  const handleDeviceChange = (event) => {
    setDevice(event.target.value);

    //disable button, only enable when items are available
    setButtonDisabled(true);
  };

  return (
    <div className="App">
      <h1>Setup Konfigurator</h1>
      <p>
        Wähle Messe, Exponat und Device aus und lade die Setup-Datei für dieses
        Gerät herunter.
      </p>
      <div className="select-container">
        <Box className="select-box">
          <FormControl fullWidth>
            <InputLabel className="select-label" id="label-for-exhibition">
              Messe
            </InputLabel>
            <Select
              labelId="label-for-exhibition"
              label="Messe"
              value={exhibition}
              onChange={handleExhibitionChange}
            >
              {allExhibitions.map((exhibition) => {
                return (
                  <MenuItem value={exhibition.id} key={exhibition.id}>
                    {exhibition["name"]}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>

        <Box className="select-box">
          <FormControl fullWidth disabled={exhibitSelectDisabled}>
            <InputLabel className="select-label" id="label-for-exhibit">
              Exponat
            </InputLabel>
            <Select
              labelId="label-for-exhibit"
              label="Exponat"
              value={exhibit}
              onChange={handleExhibitChange}
            >
              {exhibitsInExhibition.map((exhibit) => {
                return (
                  <MenuItem value={exhibit.id} key={exhibit.id}>
                    {exhibit["name"]}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>

        <Box className="select-box">
          <FormControl fullWidth disabled={deviceSelectDisabled}>
            <InputLabel className="select-label" id="label-for-device">
              Device
            </InputLabel>
            <Select
              labelId="label-for-device"
              label="Device"
              value={device}
              onChange={handleDeviceChange}
            >
              {devicesForExhibit.map((device) => {
                return (
                  <MenuItem value={device.id} key={device.id}>
                    {device["name"]}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
      </div>

      <a
        href={`${DIRECTUS_URL}/scripts/${device}?access_token=${DIRECTUS_TOKEN}`}
      >
        <Button variant="contained" disabled={buttonDisabled}>
          Download Setup File
        </Button>
      </a>
    </div>
  );
}

export default App;
